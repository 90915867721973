body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5,h6,p,a{
  margin: 0;
  font-weight: 400;
}

h1{
  font-size: 2rem;
}

h2{
  font-size: 1.125rem;
  margin: 0.375rem 0;
}

h3{
  font-size: 1rem;
  margin: 0.25rem 0;
}

h6{
  font-size: 0.75rem;
  margin: 0.125rem 0;
}

p{
  font-size: 0.75rem;
}

button{
  background: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

a{
  text-decoration: none;
}

input{
  font-size: 1.125rem;
}